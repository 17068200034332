import React, { Component } from "react";





class ImgMap extends Component {



    render() {


        return (
            <div className="row">
            <div className="col-md-12 text-center">
                <p><img src={this.props.url} className="img-fluid" alt={this.props.url} /></p>        </div>
        </div>




        )
    }
}

export default ImgMap
