import React, { Component } from "react";





class BodyList extends Component {



    render() {


        return (

            <div className="row">
                <div className="col-md-2 offset-md-2">
                    <h4>{this.props.list.titulo}</h4>
                </div>
                <div className="col-md-3">
                    <p>{this.props.list.descripcion}</p>
                </div>
                <div className="col-md-3">
                    <ul className="listado">
                        {
                            this.props.list.lista.map((item, index) => {
                                return (

                                    <li key={index}>{item.titulo}</li>
                                )


                            })
                        }
                    </ul>
                </div>
            </div>




        )
    }
}

export default BodyList
