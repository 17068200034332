import * as React from "react"
import appInsights from '../../../../AppInsights'; 
import Cliente from "../../../route/clients"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <Cliente props={props} />
      </React.Fragment>
  )
}

export default IndexPage
